import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01e87fe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "masker" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "main"
}
const _hoisted_5 = ["src"]

import { onMounted, ref, watch } from "vue";
import { imgToBase64 } from "/src/utils/ImagesHandler.js";

export default {
  props: ["title", "imageUrl", "isShow", "setShow"],
  setup(__props) {

const props = __props


const imgBase64 = ref(null);
watch(
  () => props.imageUrl,
  () => {
    if (props.imageUrl.base64) {
      imgBase64.value = props.imageUrl.base64;
    } else {
      imgToBase64(props.imageUrl).then((res) => {
        imgBase64.value = res;
      });
    }
  }
);

return (_ctx, _cache) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(__props.title || "钢信宝"), 1),
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = $event => (__props.setShow(false))),
          class: "close"
        }, "x")
      ]),
      (__props.imageUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: imgBase64.value,
              alt: ""
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "echarts")
    ])
  ], 512)), [
    [_vShow, __props.isShow]
  ])
}
}

}