
import { getHomeData } from "@/api/modules/home_api.js";
import { defineStore } from 'pinia'
import _filterTardeCover from '@/filters/filter_tarde.js'
import _ from 'lodash'

export const useHomePinia = defineStore('useHome', {
    state() {
        return {
            news: [],
            plat: [],
            loading: false,
            platCount: {
            }, // 企业用户信息
            hotspotInfo: []

        }
    },
    actions: {
        async initHomeData() {
            this.loading = true
            let res = await getHomeData()
            let { status, data } = res
            if (status) {
                const myNews = _.cloneDeep(data)
                // 处理会议展会的数据
                myNews.news.push(...myNews.hotspotInfo.map(item => {
                    item.news_list = item.hot_list
                    delete item.hot_list
                    return item
                }))
                /* 访谈专访 */
                const temp = [...myNews.interview.map(item => item.interview)]
                myNews.news.push({
                    column_title: '访谈专访',
                    news_list: temp.flat(Infinity),
                    id: -11,
                })
                this.news = _filterTardeCover(myNews.news)
                this.plat = data.plat.filter(item => {
                    item.cover = item.cover.replace(/http/g, 'https')
                    return item
                })
                this.platCount = data.platCount
                this.loading = false
            }
        }
    }
})