export function useStyle(mainTitle) {
    let className;
    
    switch (mainTitle) {
        case '最新报价':
            className = 'new-offer'
            break;
        case '贸易商机':
            className = 'trade'
            break;
        case '企业推荐':
            className = 'Recommend'
            break;
        case '平台服务':
            className = 'servicer'
            break;
        case '电子杂志':
            className = 'e-zine'
            break;

    }
    return className
}