
//图片地址转base64格式
export function imgToBase64(url) {
  let promise = new Promise((resolve) => {
    let image = new Image();
    image.crossOrigin = 'Anonymous'
    image.src = url;
    image.onload = function () {
      let base64 = getBase64Image(image);
      let file = base64toFile(base64, "img")
      resolve(file);
    }
  })
  return promise
};

// base64转file对象
export function base64toFile(base, filename) {
  var arr = base.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var suffix = mime.split("/")[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {u8arr[n] = bstr.charCodeAt(n);}
  //转换成file对象
  return new File([u8arr], `${filename}.${suffix}`, { type: mime });
};
// canvas获取base64
function getBase64Image(img) {
  let canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
  let dataURL = canvas.toDataURL("image/" + ext);
  let base64 = dataURL
  canvas.remove()
  return base64;
}

