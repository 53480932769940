import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w_1200" }

import ShowServiceVue from "./ShowService.vue";
import AHeaderVue from "@/components/common/AHeader/AHeader.vue"; // 头部内容

export default {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AHeaderVue, {
      mainTitle: "平台服务",
      subTitle: "Opportunity"
    }),
    _createVNode(ShowServiceVue)
  ]))
}
}

}