const arr = [
    {
        "id": 2,
        "region_title": "上海",
        "trend": [
        ]
    },
]

for (let i = 1; i < 30; i++) {
    const data = '2022-8-'
    arr[0].trend.push({
        "id": 1,
        "region_id": 2,
        "set_time": data + i,
        "name": "不锈钢HRB400 20MM",
        "price": Math.floor(Math.random() * (7000 - 5000) + 5000),
        "created_at": null,
        "updated_at": null
    })
}


export default arr