import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe78f152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "left_header" }
const _hoisted_3 = { class: "right_header" }
const _hoisted_4 = { class: "show" }
const _hoisted_5 = { class: "bottom_content" }
const _hoisted_6 = { class: "item_show" }
const _hoisted_7 = { class: "list" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "city dog" }
const _hoisted_10 = { class: "desc" }
const _hoisted_11 = { class: "item_show" }
const _hoisted_12 = { class: "list" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "city dog" }
const _hoisted_15 = { class: "desc" }
const _hoisted_16 = { class: "item_show" }
const _hoisted_17 = { class: "list steel_list" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "title" }

import { $getSteelData } from "@/api/modules/home_api.js";
import hq_header from "../common/hq_header.vue";
import { useSteelDynamic } from "@/pinia/api/steel-dynamic.js"; //钢市动态得store
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { usePriceHq } from "@/pinia/api/usePriceHq.js"; // 行情
import { reactive } from "@vue/reactivity";
import { nextTick, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import defaultSwiperImage from "@/assets/默认图/dongtai.jpg"; // 以后改

export default {
  setup(__props) {

const priceHq = usePriceHq();
const router = useRouter();
priceHq.getPriceHq({}, "home");

const steelDynamicStore = useSteelDynamic(); // 钢市动态得store
steelDynamicStore.getSteelDynamicData({ page: 1, limit: 10 });
// 详情
const readPriceHqDetail = (row, path) => {
  let { href } = router.resolve(`${path}${row.id}`);
  window.open(href, "_blank");
};

//当用户选择地区时触发
const changeRegion = (id) => {
  priceHq.params.region_id = id ? id : "";
  priceHq.getPriceHq({}, "home");
};

// 搜索
const searchHandler = (key) => {
  console.log(key);
  priceHq.params.info = key.value;
  priceHq.getPriceHq({}, "home");
};

const readDetail = (row) => {
  let { href } = router.resolve(`/steel/detail/${row.id}`);
  window.open(href, "_blank");
};

// 钢市动态的接口 带图
const steelState = reactive({
  list: [],
  loading: false,
});
onMounted(async () => {
  steelState.loading = true;
  let res = await $getSteelData();
  if (res.status == 1) {
    steelState.list = res.data;
    setTimeout(() => {
      steelState.loading = false;
    }, 1300);
  }
});

return (_ctx, _cache) => {
  const _component_vue3_seamless_scroll = _resolveComponent("vue3-seamless-scroll")
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")
  const _component_el_carousel = _resolveComponent("el-carousel")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(hq_header, {
          title: "价格行情",
          styleT: "linear-gradient(90deg, #1677FF, #5CA0FF)",
          border: "1px solid #1677FF",
          cityType: "price",
          onChangeQy: changeRegion,
          onRegion_change: changeRegion,
          onSearch: searchHandler,
          placeholder: "请输入关键词"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(hq_header, {
          isShowSearch: false,
          title: "钢市动态",
          styleT: "linear-gradient(90deg, #1677FF, #5CA0FF)",
          border: "1px solid #1677FF",
          showMore: true,
          morePath: "/steel/dynamic/show"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", _hoisted_6, [
          _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_7, [
            _createVNode(_component_vue3_seamless_scroll, {
              list: _unref(priceHq).homeHq.hq1,
              step: 0.5,
              hover: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(priceHq).homeHq.hq1, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    onClick: $event => (readPriceHqDetail(item, '/market/detail/'))
                  }, [
                    _createElementVNode("span", _hoisted_9, "【" + _toDisplayString(item.regions?.region_title) + "】", 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(item.title), 1)
                  ], 8, _hoisted_8))
                }), 128))
              ]),
              _: 1
            }, 8, ["list", "step"])
          ])), [
            [_directive_loading, _unref(priceHq).loading]
          ])
        ]),
        _createElementVNode("li", _hoisted_11, [
          _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_12, [
            _createVNode(_component_vue3_seamless_scroll, {
              list: _unref(priceHq).homeHq.hq2,
              step: 0.5,
              hover: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(priceHq).homeHq.hq2, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.id,
                    onClick: $event => (readPriceHqDetail(item, '/market/detail/'))
                  }, [
                    _createElementVNode("span", _hoisted_14, "【" + _toDisplayString(item.regions?.region_title) + "】", 1),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(item.title), 1)
                  ], 8, _hoisted_13))
                }), 128))
              ]),
              _: 1
            }, 8, ["list", "step"])
          ])), [
            [_directive_loading, _unref(priceHq).loading]
          ])
        ]),
        _createElementVNode("li", _hoisted_16, [
          _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_17, [
            _createVNode(_component_el_carousel, {
              "indicator-position": "outside",
              height: "252px"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(steelState).list, (item) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, { key: item }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "img-box",
                        onClick: $event => (readDetail(item))
                      }, [
                        _createElementVNode("img", {
                          loading: "lazy",
                          src: item.cover ? item.cover : _unref(defaultSwiperImage),
                          alt: ""
                        }, null, 8, _hoisted_19),
                        _createElementVNode("p", _hoisted_20, _toDisplayString(item?.title), 1)
                      ], 8, _hoisted_18)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ])), [
            [_directive_loading, _unref(steelState).loading]
          ])
        ])
      ])
    ])
  ]))
}
}

}