import { useCssVars as _useCssVars } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f903094"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-img" }
const _hoisted_2 = { class: "theme-default banner-warp shop_list_bg" }
const _hoisted_3 = {
  id: "slider",
  class: "nivoSlider"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "position_left position" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "position_right position" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "w_1200 container" }
const _hoisted_11 = { class: "show-right" }
const _hoisted_12 = { class: "show-user" }

import { nextTick, onMounted, onUnmounted, reactive, ref, computed, inject } from "vue";
import ShowUserVue from "./ShowUser.vue";
import { useImage } from "@/hooks/useImage.js";
import { useRouter } from "vue-router";
import NavTabs from "./NavTabs/NavTabs.vue";
import { useAdv } from "@/pinia/api/useAdv.js";
import { storeToRefs } from "pinia";

export default {
  setup(__props) {

_useCssVars(_ctx => ({
  "e49e2daa": (_ctx.marginLeft)
}))




const advStore = useAdv();
const loginShow = inject('login_show')
const { bannerData } = storeToRefs(advStore);
let timer = null;
// 计数
const count = ref(0);
// 当前要展示的横幅
const current = computed(() => {
  console.log(bannerData.value,1111);
  return bannerData.value[count.value]
});

const isSHowH = ref(false);

const _initSwiper = () => {
  setTimeout(() => {
    nextTick(() => {
      require("@/js/jquery-1.4.2.min.js");
      require("@/js/jquery.nivo.slider.pack.js")
      /* require("@/js/test.js") */
      // 百叶窗特效
      $(document).ready(function () {
        $("#slider").nivoSlider({
          effect: "random",
          slices: 15,
          animSpeed: 500,
          pauseTime: 3000, // 多少s轮播
          startSlide: 0, //从第几张开始
          directionNav: false, // 是否显示“上一个”和“下一个”导航
          controlNav: true,
          keyboardNav: true,
          pauseOnHover: true, // 悬停是否停止？
          manualAdvance: false, // 是否手动变化·
          randomStart: false, // 是否随机开始
          beforeChange: function (curIndex) {
            isSHowH.value = false;
            console.log(count.value,'count');
            if (curIndex) {
              count.value = curIndex;
            } else {
              if (count.value >= bannerData.value.length - 1) {
                count.value = 0;
              } else {
                count.value += 1;
              }
            }
            setTimeout(() => {
              isSHowH.value = true;
            }, 400);
          },
          /* 加载完成时触发 */
          afterLoad: function () {
            isSHowH.value = true
          }
        });
      });
    });
    isSHowH.value = true;
  }, 1000)
}
onMounted(() => {
  advStore.getBanner(_initSwiper)
});



const router = useRouter();
// 进入店铺
const loginStore = (url) => {
  const token = localStorage.getItem('token')
  if (current.value.auth && !token) {
    loginShow()
    return
  }
  // 有的话就跳转 没的话就不用跳转了。
  url && window.open(url, "_blank");
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bannerData), (item) => {
            return (_openBlock(), _createElementBlock("a", {
              target: "_blank",
              href: item.link,
              key: item.id,
              class: "info_box"
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  id: "img",
                  src: item.bannerImg
                }, null, 8, _hoisted_5)
              ])
            ], 8, _hoisted_4))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("img", {
                src: _unref(current)?.hengfu1,
                alt: "",
                onClick: _cache[0] || (_cache[0] = $event => (loginStore(_unref(current)?.hengfu1Link)))
              }, null, 8, _hoisted_7), [
                [_vShow, isSHowH.value]
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("img", {
                src: _unref(current)?.hengfu2,
                alt: "",
                onClick: _cache[1] || (_cache[1] = $event => (loginStore(_unref(current)?.hengfu2Link)))
              }, null, 8, _hoisted_9), [
                [_vShow, isSHowH.value]
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(NavTabs, { isShow: true }),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(ShowUserVue)
        ])
      ])
    ])
  ], 64))
}
}

}