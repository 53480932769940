
import {
    reactive,
    watch,
    computed
} from 'vue';
import gsap from 'gsap';

export function useAnimated(numberObj) {
    let paramsObj = numberObj.value || numberObj
    let keys = Object.keys(paramsObj)
    let worker = reactive([])
    let index = 0
    keys.forEach(key => {
        let dIndex = index
        worker.push({
            data: paramsObj[key],
            tweenedNumber: paramsObj[key],
            [key]: computed({
                get: () => {
                    return worker[dIndex].tweenedNumber.toFixed(0)
                },
            }),
            setData(value) {
                this.data = value
            }
        })
        watch(worker[dIndex], (newValue) => {
            gsap.to(worker[dIndex], {
                duration: 0.5,
                tweenedNumber: newValue.data
            })
        }, {
            deep: true
        })
        index++
    })
    return worker
}

// 要求 一一对应就可以了
export function setAllData(arr, dataArr) {
    arr.forEach((item, index) => {
        item.setData(dataArr[index])
    })

}