import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-443181e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }


import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default {
  props: ["title", "path"],
  setup(__props) {

const props = __props


const router = useRouter();
const clickMore = () => {
  const { href } = router.resolve({ path: props.path });
  window.open(href, "_blank");
};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", { onClick: clickMore }, "-" + _toDisplayString(__props.title) + "-", 1)
    ])
  ]))
}
}

}