import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e301630"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200" }
const _hoisted_2 = { class: "warp_card" }

import MyFooter from "@/template/Options_Footer.vue";
import AHeaderVue from "@/components/common/AHeader/AHeader.vue"; // 头部内容
import myTemplate from "./components/template.vue";
import defaultImg from "./components/mockData"; //默认图
import { useHomePinia } from "@/pinia/api/home.js";

export default {
  setup(__props) {

const homePinia = useHomePinia();

return (_ctx, _cache) => {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AHeaderVue, {
      mainTitle: "贸易商机",
      subTitle: "Opportunity"
    }),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(homePinia).news, (item, index) => {
        return (_openBlock(), _createBlock(myTemplate, {
          title: item.column_title,
          cur: item,
          key: item.id,
          class: _normalizeClass(index % 2 == 0 ? 'container' : ''),
          list: item.news_list,
          notMore: index > 1 ? true : false
        }, null, 8, ["title", "cur", "class", "list", "notMore"]))
      }), 128))
    ])), [
      [_directive_loading, _unref(homePinia).loading]
    ]),
    _createVNode(MyFooter, {
      title: "更多商机",
      path: "/trade/new"
    })
  ]))
}
}

}