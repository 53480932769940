import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a21af286"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "center-title" }
const _hoisted_4 = { class: "two-title" }

import { computed } from "vue";
import { useStyle } from "./computedStyle";

export default {
  props: {
  mainTitle: String,
  subTitle: String,
},
  setup(__props) {

const props = __props



const className = useStyle(props.mainTitle);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["line", _unref(className) ? _unref(className) : ''])
        }, null, 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", {
            class: _normalizeClass(["one-title", _unref(className) ? _unref(className) : ''])
          }, _toDisplayString(__props.mainTitle), 3),
          _createElementVNode("p", _hoisted_4, "-" + _toDisplayString(__props.subTitle.toLocaleUpperCase()) + "-", 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["line_right", _unref(className) ? _unref(className) : ''])
        }, null, 2)
      ])
    ])
  ]))
}
}

}