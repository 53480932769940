import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8472052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Service" }
const _hoisted_2 = { class: "service-list" }

import CardServiceVue from "./components/CardService.vue";
// pinia 初始化数据
import { useHomePinia } from "@/pinia/api/home";
import { storeToRefs } from "pinia";

export default {
  setup(__props) {

const store = useHomePinia();
const { plat } = storeToRefs(store);

return (_ctx, _cache) => {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(plat), (item, index) => {
        return (_openBlock(), _createBlock(CardServiceVue, {
          key: item.id,
          num: index + 1,
          title: item.title,
          message: item.content,
          pic: item.cover
        }, null, 8, ["num", "title", "message", "pic"]))
      }), 128))
    ])), [
      [_directive_loading, _unref(store).loading]
    ])
  ]))
}
}

}